import React from 'react'

const FaqCta = () => {
  return (
    <section className='bg-light'>
      <div className="container text-center">
        <div className="s-100"></div>
        <h2 className="title-large">Залишились питання?</h2>
        <div className="s-40"></div>
        <p>Не соромтеся їх задати - на все відповімо</p>
        <div className="s-60"></div>
        <a href="#form" className="button">Я тiльки запитати</a>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default FaqCta
