import React from 'react'
import {HiCheckCircle} from 'react-icons/hi'

const WhatDo = () => {
  return (
    <section id='whatdo' className='bg-light'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Що ми робимо для вас:</h2>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Досліджуємо ринок праці</h3>
            <p>Компанії, конкуренти, вакансії</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Складаємо унікальне резюме</h3>
            <p>Під кожну вакансію</p>
          </div>
          <div className="s-20"></div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Відбираємо вакансії</h3>
            <p>За профілем клієнта та його вимогами</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Спілкуємося з представниками вакансій</h3>
            <p>Через всі канали зв'язку</p>
          </div>
          <div className="s-20"></div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Готуємось до співбесіди</h3>
            <p>Компактно збираємо найважливішу інформацію про компанію, співробітників та фахівців з кадрів. Проводимо тренувальні співбесіди (модельні інтерв'ю).</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Оптимізуємо програму навчання</h3>
            <p>Аналізуємо кожне заняття та вдосконалюємо програму для швидшого досягнення результату.</p>
          </div>
          <div className="s-20"></div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Вивчаємо схожих кандидатів</h3>
            <p>Щоб зрозуміти, чим зацікавити різні компанії.</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Розвиваємося на LinkedIn</h3>
            <p>Підписуємося на відповідних людей, реагуємо на публікації та публікуємо пости.</p>
          </div>
          <div className="s-20"></div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Реагуємо на вакансії</h3>
            <p>Оперативно та цілодобово.</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Пишемо супровідні листи</h3>
            <p>Привертають увагу HR-фахівців.</p>
          </div>
          <div className="s-20"></div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Займаємося юридичним оформленням</h3>
            <p>Беремо на себе всю бюрократію, узгоджуємо деталі, захищаючи інтереси клієнта.</p>
          </div>
          <div className="col-md-6">
            <h3 className='title-small c-blue'><HiCheckCircle size={32} style={{margin: '4px 12px 4px 0'}} />Допомагаємо планувати день</h3>
            <p>В рамках наших послуг і загалом, щоб ви все встигали без перевантаження та стресу.</p>
          </div>
        </div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default WhatDo
