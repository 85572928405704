import React from 'react'

const Footer = () => {
  return (
    <div className='bg-black'>
      <div className="container text-center">
      <div className="s-100"></div>
      <h1 className='c-white'>👌</h1>
      <div className="s-60"></div>
      <h3 className='c-white'>Ви можете докорінно змінити своє життя за 3 місяці</h3>
      <div className="s-60"></div>
        <a href="#about" className='c-blue bg-dark rounded py-2 px-3 mx-2'>Про нас</a>
        <a href="#price" className='c-blue bg-dark rounded py-2 px-3 mx-2'>Послуги</a>
        <a href="#time" className='c-blue bg-dark rounded py-2 px-3 mx-2'>Статистика</a>
        <a href="#whatdo" className='c-blue bg-dark rounded py-2 px-3 mx-2'>Робимо за вас</a>
      <div className="s-60"></div>
      <span className='c-blue'>Konsultanty.Online © Усі права захищено.</span>
      <div className="s-100"></div>
      </div>
    </div>
  )
}

export default Footer
