import React from 'react'

const Header = () => {
  return (
    <section id='main'>
      <div className="container">
        <div className="row">
          <h1 className='e-white title-big'>Разом до вашої кар'єри за кордоном</h1>
          <div className="s-20"></div>
          <h4 className='e-white'>Допомагаємо IT-спеціалістам працевлаштуватися в іноземній компанії за 3-4 місяці.</h4>
          <div className="s-60"></div>
          <a href='#about' className='button button-white'>Кар'єра в USA</a>
          <div className="s-80"></div>
        </div>
      </div>
    </section>
  )
}

export {Header}
