import React from 'react'

import pic1 from '../assets/images/m1.svg'
import pic2 from '../assets/images/m2.svg'
import pic3 from '../assets/images/m3.svg'

const Mentors = () => {
  return (
    <section id='mentors'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Ментори:</h2>
        <div className="s-20"></div>
        <h4 className='w-50 ma py-3 title-small wh-400'>Усі ментори мають досвід роботи не менше 5-10 років. Разом ми виберемо найбільш підходящого для вас за досвідом та спілкуванням. Нижче наведений список експертів лише як приклад тих, з ким ви можете працювати.</h4>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-4 text-center">
            <img src={pic1} alt={'Андрій Сергійович'} className="w-60 ma" />
            <div className="s-20"></div>
            <h5>Андрій</h5>
            <div className="s-10"></div>
            <p>Aналітик з кібербезпеки</p>
            <small>Кібербезпекова компанія "Hacken"</small>
          </div>
          <div className="col-md-4 text-center">
            <img src={pic2} alt={'Ірина Володимирівна'} className="w-60 ma" />
            <div className="s-20"></div>
            <h5>Ірина</h5>
            <div className="s-10"></div>
            <p>Веб-розробник</p>
            <small>Стартап "ELEKS"</small>
          </div>
          <div className="col-md-4 text-center">
            <img src={pic3} alt={'Сергій Іванович'} className="w-60 ma" />
            <div className="s-20"></div>
            <h5>Сергій</h5>
            <div className="s-10"></div>
            <p>DevOps інженер</p>
            <small>IT-компанія "GlobalLogic"</small>
          </div>
          <div className="s-20"></div>
        </div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Mentors
