import React from 'react'

const Steps = () => {
  return (
    <section id='plan'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Орієнтовний план на 3 місяці:</h2>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-1">
            <h3 className='title-small c-blue'><span className='c-circle'>1</span></h3>
          </div>
          <div className="col-md-8 border-left">
            <div className="s-20"></div>
            <h5>1-ий місяць</h5>
            <h4 className='c-blue'>Знайомимося з командою</h4>
            <p>З першого дня починаємо заняття англійською мовою, визначаємо ваш рівень та складаємо індивідуальну програму навчання</p>
            <p>Визначаємо кар'єрні цілі та способи їх досягнення</p>
            <p>Вибираємо комфортного вам психолога та організуємо консультації кілька разів на тиждень</p>
            <p>Підбираємо ментора, проводимо консультацію, готуємо програму та починаємо щоденну роботу по ній</p>
            <p>Редагуємо особисті та робочі профілі в соцмережах, формуємо необхідний нетворч</p>
            <p>Аналіз ринку, попередній відбір компаній та вакансій</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-1">
            <h3 className='title-small c-blue'><span className='c-circle'>2</span></h3>
          </div>
          <div className="col-md-8 border-left">
            <div className="s-20"></div>
            <h5>2-й місяць</h5>
            <h4 className='c-blue'>Регулярні заняття із ментором</h4>
            <p>аняття із психологом: проблеми знайдені і ми працюємо над ними</p>
            <p>Заняття англійською мовою, додаткові заняття з носієм мови</p>
            <p>Фінальний відбір компаній та вакансій</p>
            <p>Старт тестових співбесід із HR</p>
            <p>Старт тестових технічних співбесід</p>
            <p>Підготовка CV та супровідних листів</p>
            <p>Усі профілі в соцмережах вже готові</p>
            <p>Активне розсилання по вакансіях</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-md-2">
          </div>
          <div className="col-md-1">
            <h3 className='title-small c-blue'><span className='c-circle'>3</span></h3>
          </div>
          <div className="col-md-8">
            <div className="s-20"></div>
            <h5>третій місяць</h5>
            <h4 className='c-blue'>Заняття з ментором та атестація</h4>
            <p>Заняття з психологом</p>
            <p>Заняття англійською мовою та атестація</p>
            <p>Тестові співбесіди з HR</p>
            <p>Тестові технічні співбесіди</p>
            <p>Консультації з кар'єрним консультантом</p>
            <p>Співбесіди із реальними представниками компаній</p>
            <p>Отримання офферів від компаній та вибір кращого</p>
            <p>Ви починаєте працювати в компанії та проходити випробувальний термін</p>
          </div>
        </div>
        <div className="s-40"></div>
        <p className="w-60 ma text-center">Це приблизний список того, що буде відбуватися з вами протягом 3 місяців. Під кожного учасника створюється унікальна програма.</p>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Steps
