import React from 'react'

const Salary = () => {
  return (
    <section>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Ваша майбутня зарплата</h2>
        <div className="s-60"></div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Спеціалізація</th>
              <th scope="col">Junior</th>
              <th scope="col">Middle</th>
              <th scope="col">Senior</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">UI/UX Designer</th>
              <td>~$50 000</td>
              <td>~$113 000</td>
              <td>~$176 000+</td>
            </tr>
            <tr>
              <th scope="row">QA Engineer</th>
              <td>~$54 000</td>
              <td>~$97 500</td>
              <td>~$141 000+</td>
            </tr>
            <tr>
              <th scope="row">Project Manager</th>
              <td>~$47 000</td>
              <td>~$96 000</td>
              <td>~$145 000+</td>
            </tr>
            <tr>
              <th scope="row">Product Manager</th>
              <td>~$63 000</td>
              <td>~$124 000</td>
              <td>~$185 000+</td>
            </tr>
            <tr>
              <th scope="row">Business Analyst</th>
              <td>~$49 000</td>
              <td>~$92 500</td>
              <td>~$136 000+</td>
            </tr>
            <tr>
              <th scope="row">DevOps</th>
              <td>~$76 000</td>
              <td>~$133 000</td>
              <td>~$190 000+</td>
            </tr>
            <tr>
              <th scope="row">Data Science</th>
              <td>~$54 000</td>
              <td>~$127 000</td>
              <td>~$200 000+</td>
            </tr>
            <tr>
              <th scope="row">Data Analyst</th>
              <td>~$47 000</td>
              <td>~$86 000</td>
              <td>~$125 000+</td>
            </tr>
            <tr>
              <th scope="row">System Admin</th>
              <td>~$42 000</td>
              <td>~$88 000</td>
              <td>~$130 000+</td>
            </tr>
            <tr>
              <th scope="row">Data Engineer</th>
              <td>~$70 000</td>
              <td>~$126 500</td>
              <td>~$183 000+</td>
            </tr>
          </tbody>
        </table>
        <div className="s-60"></div>
        <p className="w-80 ma text-center">Ми шукаємо роботу в Америці, тому що там більше вакансій і вищий рівень оплати праці. У таблиці представлена інформація про річну заробітну плату в США. Дані взяті з порталу glassdoor.com та не оновлюються в реальному часі.</p>
        <div className="s-50"></div>
        <div className="text-center"><a href="#form" className='button'>Заробляти бiльше</a></div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Salary
