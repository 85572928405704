import React from 'react'

const Time = () => {
  return (
    <section id='time' className='bg-light'>
      <div className="container">
        <div className="s-60"></div>
        <h2 className="title-large text-center">Час - це гроші:</h2>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-3">
            <h3 className='title-small c-dark border-bottom pb-3 mb-3'>~4 години щодня</h3>
            <p>Витрачається на самостійний пошук роботи</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-dark border-bottom pb-3 mb-3'>~90 днів</h3>
            <p>Якщо ви будете ефективні кожного дня і кожну хвилину</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-dark border-bottom pb-3 mb-3'>~100 вакансій на тиждень</h3>
            <p>Потрібно обробити, щоб досягнути бажаної пропозиції</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-dark border-bottom pb-3 mb-3'>20 000 $</h3>
            <p>Саме стільки ви можете заробити більше за рік, якщо скористаєтесь нашою послугою.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Time
