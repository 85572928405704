import React from 'react'

const Cta = () => {
  return (
    <section className='bg-dark'>
      <div className="container">
        <h2 className="title-large text-center e-white">Все буде добре!</h2>
        <div className="s-40"></div>
        <p className="title-small e-white">Ми самі шукали роботу за кордоном: не знали, куди поїхати, як отримати візу, де знайти роботу, як зібрати кошти на переїзд, як адаптуватися до нового місця. Ми зробили це самостійно, але втрачали багато часу, грошей та мотивації. Тепер ми допомагаємо іншим пройти цей шлях - швидко та без помилок. Ви не самотні!</p>
        <div className="s-100"></div>
        <div className="text-center"><a href="#price" className='button button-white c-black'>Обрати формат</a></div>
      </div>
    </section>
  )
}

export {Cta}
