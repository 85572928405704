import React from 'react'

const Form = () => {
  return (
    <section id='form'>
      <div className="container text-center">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Почніть заощаджувати час прямо зараз</h2>
        <div className="s-100"></div>
        <iframe
          title='form'
          className='airtable-embed'
          src='https://airtable.com/embed/shr9TFv7yHg9X8bwS?backgroundColor=pinkDusty'
          frameBorder='0'
          scrolling='no'
          width='100%'
          height='1400px'
          style={{ backgroundColor: '#fff' }}
        />
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Form
