import React from 'react'

const Nav = () => {
  return (
    <div className="nav">
      <div className="container my-4 ma">
        <div className="row">
          <div className="col-md-5">
            <a href='#time' className='mr-3 e-white'>Статистика</a>
            <a href='#price' className='mx-3 e-white'>Послуги</a>
            <a href='#team' className='mx-3 e-white'>Команда</a>
          </div>
          <div className="col-md-2 e-white">KONSULTANTY.ONLINE</div>
          <div className="col-md-4 text-right">
            <a href='#plan' className='mx-3 e-white'>Програма</a>
            <a href='#mentors' className='mx-3 e-white'>Ментори</a>
            <a href='#faq' className='ml-3 e-white'>Питання</a>
          </div>
        </div>
      </div>

    </div>
  )
}

export {Nav}
