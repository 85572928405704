import React from 'react'

const Pricing = () => {
  return (
    <section id='price' className='bg-dark'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="text-center c-white">Оберіть підходящий формат</h2>
        <div className="s-80"></div>
        <div className="row">
          <div className="col-md-4 bg-dark text-center">
            <div className="m-4 card card-pricing rounded border bg-dark p-3">
              <h4 className="c-white py-3 h-10">Все сам</h4>
              <h3 className="c-blue py-3">всього<br />750 доларів</h3>
              <p className="c-lite p-2 text-left"><small>від 60 доларів на місяць</small></p>
              <p className="c-lite p-2 text-left"><small>Крок за кроком посібник для самостійної підготовки</small></p>
              <p className="c-lite p-2 text-left"><small>Шаблони документів: резюме, мотиваційний лист, профіль на LinkedIn тощо.</small></p>
              <p className="c-lite p-2 text-left"><small>Чек-листи для самоперевірки</small></p>
              <p className="c-lite p-2 text-left"><small>Один консультаційний сеанс на місяць на вибір — з наставником, викладачем, фахівцем з управління людськими ресурсами.</small></p>
              <div className="card-footer px-0 pt-3">
                <a href="#form" className="button button-white"><small>Купити</small></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 bg-dark text-center">
            <div className="m-0 card card-pricing rounded border bg-dark p-3">
              <h4 className="c-white py-3 h-10">Все за мене</h4>
              <h3 className="c-blue py-3">всього<br />9 000 доларів</h3>
              <p className="c-lite p-2 text-left"><small>від 250 доларів на місяць</small></p>
              <p className="c-lite p-2 text-left"><small>Упакування професійного досвіду</small></p>
              <p className="c-lite p-2 text-left"><small>Оформлення Facebook, LinkedIn, CV, Cover letter</small></p>
              <p className="c-lite p-2 text-left"><small>Підвищення навичок з наставником</small></p>
              <p className="c-lite p-2 text-left"><small>Покращення англійської мови</small></p>
              <p className="c-lite p-2 text-left"><small>Навички успішного проходження співбесід</small></p>
              <p className="c-lite p-2 text-left"><small>Допомога психолога</small></p>
              <p className="c-lite p-2 text-left"><small>Супроводження на випробувальному терміні</small></p>
              <p className="c-lite p-2 text-left"><small>Підтримка 24/7</small></p>
              <div className="card-footer px-0 pt-3">
                <a href="#form" className="button button-blue"><small>Працевлаштуватися</small></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 bg-dark text-center">
            <div className="m-4 card card-pricing rounded border bg-dark p-3">
              <h4 className="c-white py-3 h-10">Все за мене +</h4>
              <h3 className="c-blue py-3">всього<br />20 000 доларів</h3>
              <p className="c-lite p-2 text-left"><small>від 550 доларів на місяць</small></p>
              <p className="c-lite p-2 text-left"><small>Упакування професійного досвіду</small></p>
              <p className="c-lite p-2 text-left"><small>Оформлення Facebook, LinkedIn, CV, Cover letter</small></p>
              <p className="c-lite p-2 text-left"><small>Підвищення навичок з наставником</small></p>
              <p className="c-lite p-2 text-left"><small>Покращення англійської мови</small></p>
              <p className="c-lite p-2 text-left"><small>Навички успішного проходження співбесід</small></p>
              <p className="c-lite p-2 text-left"><small>Допомога психолога</small></p>
              <p className="c-lite p-2 text-left"><small>Супроводження на випробувальному терміні</small></p>
              <p className="c-lite p-2 text-left"><small>Юридичний захист (договір з роботодавцем)</small></p>
              <div className="card-footer px-0 pt-3">
                <a href="#form" className="button"><small>Працевлаштуватися та переїхати</small></a>
              </div>
            </div>
          </div>
          <div className="s-40"></div>
        <p className='w-80 ma py-3 text-center wh-400 c-white'>В тарифах зазначена повна вартість і мінімальний розмір щомісячного платежу при покупці у розстрочку. Залишок суми, якщо ви обираєте розстрочку, можна сплатити протягом 1-2 місяців із зарплати на новій роботі або продовжити користуватися коштами банку.</p>
        </div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Pricing
