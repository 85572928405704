import React from 'react'
import pic from '../assets/images/photo.svg'

const About = () => {
  return (
    <>
    <section id='about'>
      <div className="container">
        <h2 className="title-large text-center">З нами ви:</h2>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-3">
            <h3 className='title-small c-blue'>Покращите свою англійську мову</h3>
            <p>Для успішних співбесід та вільного спілкування з колегами та друзями.</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-blue'>Упакуєте ваш досвід</h3>
            <p>Виділимо ваші сильні сторони, заповнимо прогалини в знаннях, грамотно оформимо ваше резюме та профіль на LinkedIn.</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-blue'>Працевлаштуєтесь</h3>
            <p>Знайдемо роботу до душі, і ви зможете заробляти щонайменше на $20,000 більше на рік, ніж зараз.</p>
          </div>
          <div className="col-md-3">
            <h3 className='title-small c-blue'>Переїдете в іншу країну</h3>
            <p>Допомагаємо з візою, організацією переїзду та пошуком житла.</p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container text-center">
        <img src={pic} alt={'Андрiй Роговський'} className='p-3' />
        <h4 className='c-blue w-80 ma py-3 title-small'>Ми не навчаємо, як складати резюме, і не робимо це повністю за вас - але робимо це разом з вами. Ми вважаємо, що тільки комплексний підхід та стабільна підтримка приведуть до отримання пропозиції вашої мрії.</h4>
        <div className="s-20"></div>
        <h2 className="title-small text-center">Катерина</h2>
        <p>Керiвник Konsultanty.Online</p>
        <div className="s-100"></div>
      </div>
    </section>
    </>
  )
}

export {About}
