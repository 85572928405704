import React, { useState } from 'react'
import {HiPlus, HiMinus} from 'react-icons/hi2'

const Faq = () => {
  const [active,setActive] = useState(false)

  return (
    <section id='faq'>
      <div className="container">
        <div className="item border-bottom border-top">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Який рівень потрібен для участі у програмі?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Початковий рівень (Junior) чи вище. Тоді 3-4 місяців достатньо, щоб розпочати кар'єру на міжнародному ринку. Щоб придбати нову спеціальність, потрібно від 3 до 12 місяців та багато часу на самостійне вивчення. Наші експерти та програма тут не допоможуть.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Скільки часу мені потрібно виділяти?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Ми створимо індивідуальну програму, виходячи з ваших можливостей та побажань. Можливо, це займе 1 годину щодня або 2-3 рази на тиждень по 3 години.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Чи реально влаштуватися працювати за кордоном за 3-4 місяці?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Більш як реально, і це лише орієнтовні терміни, оскільки можна отримати оффер вже через 1-2 місяці. Але процес також може і затягтися, якщо ви не намагатиметеся достатньо з вашого боку.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Чи зможу я віддалено працювати?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Ми шукаємо віддалений варіант роботи, щоб ви могли працювати по ФОП-3.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Хто буде моїм ментором?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Спочатку ми підберемо досвідчених експертів із вашої професійної галузі з максимально релевантним досвідом. Далі проведемо короткі сесії-знайомства та виберемо оптимальну кандидатуру. Нам важливо, щоб між вами та наставниками знайшлася спільна мова та взаєморозуміння.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Навіщо потрібне спілкування з психологом?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Швидкий темп життя та зміни (як внутрішні, так і зовнішні) – завжди великий стрес для психіки. У напрузі люди менш ефективні, роблять помилки через неуважність. Психолог допоможе вам розслабитися, знайти внутрішній баланс, краще впізнати себе та здобути впевненість у своїх силах. Такий кандидат справить на роботодавця більш позитивне враження.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>У мене вже є свій ментор/психолог/вчитель англійської мови тощо.</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Ви можете продовжувати займатися з ними, але наша команда буде підключена в повному складі. Ми зможемо позитивно впливати на результат, лише працюючи з нашими партнерами та співробітниками. По-перше, ми у них впевнені. По-друге, дотримуючись вашої індивідуальної програми та комплексно спостерігаючи ваш прогрес, всі експерти працюють синхронно – на ваш результат.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Що якщо не вийде або я не впораюсь із навантаженням?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Ми віримо в ефективність нашої програми, оскільки пройшли її самі та залучили до роботи над нею досвідчених експертів. Також з вами на зв'язку буде куратор, який збирає зворотний зв'язок, відстежує навантаження та постійно оптимізує програму. Але якщо ви систематично пропускаєте заняття та не відповідаєте на дзвінки, ми будемо змушені перервати надання послуги. У цьому випадку ми повернемо вам гроші пропорційно до обсягу виконаних робіт.</p>
            <p>Якщо ви відмовитеся оформити повернення і все ж таки захочете продовжити, то ми надамо інструкції, як продовжити самостійно, при цьому заняття з ментором та іншими фахівцями припиняться. Ми перевірятимемо ваш прогрес раз на тиждень і даватимемо подальші рекомендації. Однак, якщо ви так і не отримаєте оффер, ми не зможемо повернути вам гроші, оскільки не можемо гарантувати працевлаштування.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Як відбувається навчання англійської мови?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>На вас чекають 30-хвилинні заняття 2 рази на день — з викладачем англійської мови та носієм. Ви обговорюватимете вашу професію і в цілому життя. Наша мета – зробити так, щоб ви вільно і грамотно могли говорити як про професійні, так і особисті справи.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Як оплатити?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Є два способи:<br />
            - Одноразовий платіж (у цьому випадку ми надаємо знижку 2,5%)<br />
            - Оплата рівними частинами щомісяца</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Чому така суттєва різниця у вартості між форматами?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>Все дуже просто: під кожного клієнта ми наймаємо окремих спеціалістів. Всі вони працюють з нами на контрактній основі, та їх послуги коштують дорого. Крім того, у нашій команді є штатні фахівці, які також одержують зарплату. Коли ви працюєте за інструкцією, ви витрачаєте лише свій час. Тому формат «Все сам» значно дешевше, ніж інші. А «Все за мене +» включає юридичний супровід.</p>
          </div> : ''}
        </div>
        <div className="item border-bottom">
          <div className="w-100 flex jcsb aic p-4">
            <h5 className='c-blue title-small'>Чи буде у мене вибір, у якій компанії працювати?</h5>
            <span
              onClick={() => {
                active ? setActive(false) : setActive(true)
              }}
            >{active ? <HiMinus /> : <HiPlus/>}</span>
          </div>
          {active ?
          <div className="py-3 px-4">
            <p>В результаті нашої з вами роботи ви отримаєте кілька офферів та зможете самі вирішити, яка пропозиція вам більше подобається. Якщо ви сумніваєтеся, яке з них вибрати, ми з радістю допоможемо вам визначитися!</p>
          </div> : ''}
        </div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Faq
