import React from 'react'

const Features = () => {
  return (
    <section id='team'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center">Вас підтримають наші експерти та команда.</h2>
        <div className="s-100"></div>
        <div className="row">
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>1</span>
            Ментор з вашої спеціалізації</h3>
            <p>Оцініть ваші сильні сторони, надасте досягнення і побачите прогалини, намітіть нові горизонти</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>2</span>
            Кар'єрний консультант</h3>
            <p>Зрозумієте очікування щодо завдань, команди, зарплати, корпоративної культури. Створіть план розвитку вашої кар'єри на найближчі роки</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>3</span>
            HR-фахівець</h3>
            <p>Знайде потрібні вам вакансії, розкаже про ринок праці, складе резюме та супровідні листи під кожну вакансію, відповідатиме на запити</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>4</span>
            Тренер по публічних виступах:</h3>
            <p>Покращить навички самопрезентації.<br />Допоможе вам виступати впевненіше і спокійніше на співбесідах.</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>5</span>
            Викладач англійської мови:</h3>
            <p>Підготує вас до співбесід.<br />Розширить ваш словниковий запас і ознайомить зі специфічною термінологією.</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>6</span>
            Нейтiв спiкер:</h3>
            <p>Покращить ваше вимовляння.<br />Навчить вас використовувати мову більш природно і вільно в різних життєвих ситуаціях, не тільки на роботі.</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>7</span>
            Куратор:</h3>
            <p>Слідкує за вашим прогресом і коригує програму, щоб досягти результату швидше, не перевантажуючи вас.</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>8</span>
            Психолог:</h3>
            <p>Допоможе впоратися зі стресом.<br />Краще пізнати себе і знайти внутрішню рівновагу.<br />Покращить комунікацію з колегами.</p>
          </div>
          <div className="col-md-4">
            <h3 className='title-small c-blue'><span className='c-circle'>9</span>
            Особистий помічник:</h3>
            <p>Спростить і оптимізує щоденні завдання.<br />Звільнить час для інших важливих справ і покращення якості життя.</p>
          </div>
        </div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default Features
