import React from 'react'
import './styles/custom.sass'

import {Nav} from './components/Nav'
import {Header} from './components/Header'
import {About} from './components/About'
import {Cta} from './components/Cta'
import Features from './components/Features'
import Time from './components/Time'
import Salary from './components/Salary'
import WhatDo from './components/WhatDo'
import Mentors from './components/Mentors'
import Pricing from './components/Pricing'
import WhatIs from './components/WhatIs'
import Steps from './components/Steps'
import Form from './components/Form'
import Faq from './components/Faq'
import FaqCta from './components/FaqCta'
// import Contact from './components/Contact'
import Footer from './components/Footer'

const App = () => {
  return (
    <div>
      <div className="bg-top">
        <Nav />
        <Header />
      </div>
      <About />
      <Cta />
      <Features />
      <Time />
      <Salary />
      <WhatDo />
      <Mentors />
      <Pricing />
      <WhatIs />
      <Steps />
      <Form />
      <Faq />
      <FaqCta />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export {App}
