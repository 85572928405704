import React from 'react'

const WhatIs = () => {
  return (

    <section className='bg-blue'>
      <div className="container">
        <div className="s-100"></div>
        <h2 className="title-large text-center c-white">Що, якщо не вийде?</h2>
        <div className="s-40"></div>
            <p className="w-70 ma c-white">Вам потрібно зробити все можливе, щоб ми не прийняли вас на роботу в іноземну компанію. Наприклад, зовсім не спілкуватися з нами. Ну, або має настати кінець світу. Проте, ви завжди можете передумати. Тоді гроші повернуться вам протягом 10 днів після вирахування фактичних витрат на надання послуг. Просто зв'яжіться з вашим куратором або напишіть нам на пошту.</p>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export default WhatIs
